<template>
  <div class="section">
    <Modal />
    <Header class="opaque" :opaque="true"/>
    <div class="content">
      <div class="cards-container">
        <div class="card">
          <img src="@/assets/card1.png" alt="Card image 1" draggable="false">
          <div class="title">Name, Title</div>
          <div class="desc">Share the amazing things customers are saying about your business.
            Double click, or click Edit Text to make it yours.</div>
        </div>
        <div class="card">
          <img src="@/assets/card2.png" alt="Card image 2" draggable="false">
          <div class="title">Name, Title</div>
          <div class="desc">Share the amazing things customers are saying about your business.
            Double click, or click Edit Text to make it yours.</div>
        </div>
        <div class="card">
          <img src="@/assets/card3.png" alt="Card image 3" draggable="false">
          <div class="title">Name, Title</div>
          <div class="desc">Share the amazing things customers are saying about your business.
            Double click, or click Edit Text to make it yours.</div>
        </div>
      </div>
      <div class="subscribe">
        <div class="text">
          <h2>Subscribe to news</h2>
          <h3>Allstate Cargo</h3>
        </div>
        <div class="right">
          <form @submit.prevent="submit">
            <input
              type="text"
              autocomplete="name"
              placeholder="Name"
              required
              v-model="name"
            >
            <input
              type="email"
              autocomplete="email"
              placeholder="Email"
              required
              v-model="email"
            >
            <Button type="submit">SUBSCRIBE</Button>
          </form>
          <p>By clicking the "Submit" button, you agree to process personal data and confirm that
            you are of legal age in accordance with the <a>terms</a>.</p>
        </div>
      </div>
    </div>
    <Footer />
  </div>
</template>

<script>
import Header from '@/components/Header.vue';
import Footer from '@/components/Footer.vue';
import Button from '@/components/Button.vue';
import axios from 'axios';
import Modal from '@/components/Modal.vue';

export default {
  components: {
    Header,
    Footer,
    Button,
    Modal,
  },
  data() {
    return {
      name: '',
      email: '',
    };
  },
  methods: {
    async submit() {
      const { name, email } = this;
      if (name && email) {
        const formData = new FormData();
        formData.append('name', name);
        formData.append('email', email);
        try {
          const res = await axios.post('/contacts', formData);
          if (res.status === 200) {
            this.showModal();
          }
        } catch (e) {
          console.log('Error occured during form submit');
          console.error(e);
        }
      }
    },
    showModal() {
      document.getElementById('modalContainer').style.display = 'flex';
    },
  },
};
</script>

<style lang="sass" scoped>
.section
  position: relative
  .content
    height: calc(100% - 128px - 170px)
    width: 1520px
    margin-left: auto
    margin-right: auto
    padding-top: 70px
    .cards-container
      display: flex
      justify-content: space-evenly
      margin: 0px auto 83px
      .card
        img
        .title
          font-weight: 500
          font-size: 24px
          line-height: 41px
          color: #000000
        .desc
          font-size: 11px
          line-height: 18px
          color: #000000
          width: 382px
    .subscribe
      width: fit-content
      height: fit-content
      margin: 0px auto
      border: 5px solid var(--accent-color)
      padding: 46px 87px
      display: flex
      .text
        margin-right: 49px
        h2
          font-weight: 500
          font-size: 30px
          line-height: 39px
          color: #262626
          width: 281px
        h3
          font-weight: 500
          font-size: 20px
          line-height: 26px
          color: #acacac
      .right
        display: flex
        flex-direction: column
        form
          display: flex
          input
            margin-right: 15px
            border: 2px solid #f0f0f0
            font-size: 14px
            color: #000000
            padding: 8px 18px
            width: 191px
            &::placeholder
              font-size: 14px
              color: #acacac
        p
          font-size: 10px
          line-height: 17px
          color: #acacac
          margin-top: 15px
          width: 398px
          a
            color: var(--accent-color)
            cursor: pointer
//##Device = Laptops, Desktops
@media (min-width: 1366px) and (max-width: 1919px)
  .section
    .content
      height: calc(100% - 80px - 120px)
      width: 1320px
      padding-top: 50px
      .cards-container
        margin: 0px auto 50px
      .subscribe
        padding: 36px 40px
//##Device = Tablets, Ipads (portrait)
@media (min-width: 768px) and (max-width: 1365px)
  .section
    background-color: #fff
    position: relative
    .content
      height: auto
      width: 100%
      padding: 0px 40px
      padding-top: 70px
      margin-bottom: 201px
      .cards-container
        display: flex
        flex-direction: column
        justify-content: center
        align-items: center
        margin: 0px auto 239px
        .card
          margin-bottom: 70px
          &:last-child
            margin-bottom: 0px
          img
          .title
            font-weight: 500
            font-size: 36px
            line-height: 61px
          .desc
            font-size: 20px
            line-height: 34px
            width: 100%
            max-width: 700px
      .subscribe
        width: 100%
        max-width: 800px
        height: auto
        margin: 0px auto
        padding: 89px 48px
        display: flex
        flex-direction: column
        .text
          margin-right: 0px
          h2
            font-size: 36px
            line-height: 47px
            width: 100%
          h3
            font-size: 22px
            line-height: 29px
            margin: 48px 0px 36px 0px
        .right
          display: flex
          flex-direction: column
          form
            display: flex
            flex-direction: column
            input
              margin-right: 0px
              margin-bottom: 25px
              font-size: 20px
              line-height: 24px
              padding: 14px 72px
              width: 100%
              &::placeholder
                font-size: 20px
            button
              width: 100%
          p
            font-size: 20px
            line-height: 34px
            margin-top: 25px
            width: 100%
            a
//##Device = Most of the Smartphones Mobiles (Portrait)
@media (min-width: 320px) and (max-width: 767px)
  .section
    background-color: #fff
    position: relative
    .content
      height: auto
      width: 100%
      padding: 0px 15px
      padding-top: 70px
      margin-bottom: 60px
      .cards-container
        display: flex
        flex-direction: column
        justify-content: center
        align-items: center
        margin: 0px auto 70px
        .card
          margin-bottom: 35px
          &:last-child
            margin-bottom: 0px
          img
          .title
            font-weight: 500
            font-size: 24px
            line-height: 41px
          .desc
            font-size: 14px
            line-height: 21px
            width: 290px
      .subscribe
        width: 100%
        height: auto
        margin: 0px
        padding: 35px 20px
        display: flex
        flex-direction: column
        .text
          margin-right: 0px
          h2
            font-size: 26px
            line-height: 35px
            width: 100%
          h3
            font-size: 20px
            line-height: 26px
            margin: 20px 0px 35px 0px
        .right
          display: flex
          flex-direction: column
          form
            display: flex
            flex-direction: column
            input
              margin-right: 0px
              margin-bottom: 15px
              font-size: 14px
              padding: 15px 30px
              width: 100%
              &::placeholder
                font-size: 14px
            button
              width: 100%
          p
            font-size: 10px
            line-height: 17px
            margin-top: 15px
            width: 100%
            a
</style>
