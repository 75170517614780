import Vue from 'vue';
import axios from 'axios';
// import VueAnalytics from 'vue-ua';
// import VueYandexMetrika from 'vue-yandex-metrika';
import VueAnimate from 'vue-animate-scroll';
import * as VueGoogleMaps from 'vue2-google-maps';
import router from './router';
import App from './App.vue';
import './plugins/fullpage';
import Consts from './util/consts';

Vue.config.productionTip = false;

axios.defaults.baseURL = Consts.BASEURL;

// Vue.use(VueAnalytics, Consts.GOOGLE_ANALYTICS_CONFIG);

// Vue.use(VueYandexMetrika, Consts.YANDEX_METRIKA_CONFIG);

Vue.use(VueAnimate);

Vue.use(VueGoogleMaps, {
  load: {
    key: Consts.mapApiKey,
  },
});

new Vue({
  router,
  render: (h) => h(App),
}).$mount('#app');
