<template>
  <footer>
    <a href="#block0" class="arrow-up" draggable="false">
      <ArrowUp />
    </a>
    <div class="content">
      <div class="left">
        <img src="@/assets/logofooter.png" alt="Allstate Cargo logo" draggable="false">
        <p>Copyrights © 2020 All Rights Reserved by Allstate Cargo Company</p>
      </div>
      <nav>
        <div>
          <router-link to="/" draggable="false">Home</router-link>
          <router-link to="/services" draggable="false">Services</router-link>
          <router-link to="/about-us" draggable="false">About Us</router-link>
          <router-link to="/careers" draggable="false">Careers</router-link>
        </div>
        <div>
          <a href="" draggable="false">Facebook</a>
          <a href="" draggable="false">Instagram</a>
          <a href="" draggable="false">Twitter</a>
          <router-link to="" draggable="false"><span>Privacy Policy</span></router-link>
        </div>
      </nav>
    </div>
  </footer>
</template>

<script>
import ArrowUp from '@/assets/icons/arrowup.svg';

export default {
  components: {
    ArrowUp,
  },
};
</script>

<style lang="sass" scoped>
footer
  width: 100%
  height: 170px
  background-color: #f6f6f6
  position: relative
  .arrow-up
    position: absolute
    right: 85px
    bottom: 88px
  .content
    width: 1520px
    margin: 0px auto
    display: flex
    justify-content: space-between
    .left
      display: flex
      flex-direction: column
      justify-content: flex-start
      margin-top: 49px
      img
        width: 100px
        opacity: 0
      p
        color: #acacac
        margin-top: 15px
    nav
      display: flex
      margin-top: 27px
      div
        margin-right: 250px
        display: flex
        flex-direction: column
        align-items: flex-start
        &:last-child
          margin-right: 0px
        a
          font-size: 14px
          color: #262626
          margin-bottom: 12px
          &:hover
            text-decoration: underline
          span
            color: #acacac
//##Device = Laptops, Desktops
@media (min-width: 1366px) and (max-width: 1919px)
  footer
    height: 120px
    .arrow-up
      transform: scale(0.625)
      right: 50px
      bottom: 50px
    .content
      width: 1080px
      .left
        margin-top: 20px
        img
        p
      nav
        margin-right: 50px
        margin-top: 12px
        div
          margin-right: 156px
          a
            font-size: 13px
            margin-bottom: 8px
            span
//##Device = Tablets, Ipads (portrait)
@media (min-width: 768px) and (max-width: 1365px)
  footer
    width: 100%
    height: auto
    position: relative
    .arrow-up
      display: none
    .content
      width: 100%
      padding: 0px 40px
      margin: 0px
      display: flex
      justify-content: space-between
      .left
        display: flex
        flex-direction: column
        justify-content: flex-start
        margin-top: 30px
        img
          width: 201px
          height: 60px
        p
          font-size: 16px
          line-height: 27px
          margin-top: 30px
          width: 200px
      nav
        display: flex
        margin: 30px 40px 80px 0px
        div
          margin-right: 90px
          display: flex
          flex-direction: column
          align-items: flex-start
          &:last-child
            margin-right: 0px
          a
            font-size: 20px
            line-height: 34px
            margin-bottom: 20px
            span
//##Device = Most of the Smartphones Mobiles (Portrait)
@media (min-width: 320px) and (max-width: 767px)
  footer
    width: 100%
    height: auto
    position: relative
    .arrow-up
      display: none
    .content
      width: 100%
      padding: 0px 15px
      margin: 0px
      display: flex
      justify-content: space-between
      .left
        display: flex
        flex-direction: column
        justify-content: flex-start
        margin-top: 30px
        img
          width: 50px
          height: 15px
        p
          font-size: 7px
          line-height: 12px
          margin-top: 15px
          width: 100px
      nav
        display: flex
        margin: 30px 0px 80px 0px
        div
          margin-right: 30px
          display: flex
          flex-direction: column
          align-items: flex-start
          &:last-child
            margin-right: 0px
          a
            font-size: 13px
            line-height: 21px
            margin-bottom: 20px
            span
</style>
