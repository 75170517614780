<template>
  <div class="mobile-header-wrapper">
    <header>
      <router-link to="/" class="logo-link">
        <img class="logo" src="@/assets/logo.png" alt="Allstate Cargo CO. logo">
      </router-link>
      <div
        class="menu-icon"
        @click="showMenu"
      >
        <MenuIcon />
      </div>
    </header>
    <div class="menu" ref="menu">
      <CloseIcon class="close" @click="close" />
      <router-link to="/" class="logo-link">
        <img src="@/assets/logo1.png" alt="Allstate Cargo CO. logo" class="logo">
      </router-link>
      <nav>
        <a href="/" draggable="false">HOME</a>
        <a href="/about-us" draggable="false">ABOUT US</a>
        <a href="/services" draggable="false">SERVICES</a>
        <a href="/careers" draggable="false">CAREERS</a>
        <a href="/contact-us" draggable="false">CONTACT US</a>
      </nav>
    </div>
  </div>
</template>

<script>
import MenuIcon from '@/assets/icons/menu.svg';
import CloseIcon from '@/assets/icons/close.svg';

export default {
  components: {
    MenuIcon,
    CloseIcon,
  },
  methods: {
    showMenu() {
      this.$refs.menu.style.left = '0';
      this.$refs.menu.style.top = '0';
    },
    close() {
      this.$refs.menu.style.left = '-100%';
      this.$refs.menu.style.top = '-20vh';
    },
  },
};
</script>

<style lang="sass" scoped>
.mobile-header-wrapper
  height: 85px
  background-color: transparent
  width: 100%
  header
    padding: 25px 0px
    display: flex
    justify-content: space-between
    .logo-link
      width: 112px
      height: 38px
      .logo
        width: inherit
        height: inherit
    .menu-icon
      cursor: pointer
  .menu
    position: absolute
    z-index: 1001
    top: -20vh
    left: -100%
    width: 100%
    height: 100vh
    min-width: 100%
    min-height: 100vh
    background-color: #ffffff
    transition: all 0.3s ease-out
    display: flex
    flex-direction: column
    align-items: center
    justify-content: space-between
    padding: 101px 0px 94px 0px
    .close
      position: absolute
      top: 34px
      right: 15px
      transform: scale(0.8)
    .logo-link
      .logo
        width: 112px
        height: 38px
    nav
      display: flex
      flex-direction: column
      a
        text-align: center
        color: var(--accent-color)
        font-size: 16px
        line-height: 19px
        margin-top: 28px
        &:first-child
          margin-top: 0px
@media (min-width: 1920px)
  .mobile-header-wrapper
    display: none
//##Device = Laptops, Desktops
@media (min-width: 1366px) and (max-width: 1919px)
  .mobile-header-wrapper
    display: none
//##Device = Tablets, Ipads (portrait)
@media (min-width: 768px) and (max-width: 1365px)
  .mobile-header-wrapper
    height: 131px
    header
      padding: 31px 0px
      .logo-link
        width: 150px
        height: 51px
        .logo
          width: inherit
          height: inherit
      .menu-icon
        margin-top: 10px
        svg
          transform: scale(1.5)
    .menu
      align-items: center
      justify-content: space-between
      padding: 101px 0px 94px 0px
      .close
        position: absolute
        top: 44px
        right: 40px
        transform: scale(1.5)
      .logo-link
        .logo
          width: 150px
          height: 51px
      nav
        display: flex
        flex-direction: column
        a
          font-size: 30px
          line-height: 36px
          margin-top: 46px
</style>
