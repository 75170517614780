<template>
  <div class="section">
    <Header class="opaque" :opaque="true"/>
    <div class="content">
      <h2>Gallery</h2>
        <coverflow
          :coverList="coverList"
          :width="coverflowWidth"
          :coverWidth="coverflowCoverWidth"
          :coverHeight="coverflowCoverHeight"
          :index="1"
          ref="coverflowref"
          id="coverflow"
        ></coverflow>
    </div>
  </div>
</template>

<script>
import Header from '@/components/Header.vue';
import coverflow from 'vue-coverflow';
import Truck1 from '@/assets/truck1.png';
import Truck2 from '@/assets/truck2.png';
import Truck3 from '@/assets/truck3.png';

export default {
  components: {
    Header,
    coverflow,
  },
  data() {
    return {
      coverList: [
        {
          cover: Truck1,
        },
        {
          cover: Truck2,
        },
        {
          cover: Truck3,
        },
      ],
      windowWidth: document.body.clientWidth,
      windowHeight: document.body.clientHeight,
    };
  },
  computed: {
    coverflowWidth() {
      if (this.windowWidth >= 1920) {
        return 1320;
      }
      if (this.windowWidth >= 1366) {
        return 1080;
      }
      if (this.windowWidth >= 1024) {
        return 780;
      }
      if (this.windowWidth >= 320) {
        return 550;
      }
      return 600;
    },
    coverflowCoverWidth() {
      if (this.windowWidth >= 1920) {
        return 800;
      }
      if (this.windowWidth >= 1366) {
        return 600;
      }
      if (this.windowWidth >= 1024) {
        return 400;
      }
      if (this.windowWidth >= 320) {
        return 275;
      }
      return 300;
    },
    coverflowCoverHeight() {
      return 400;
    },
  },
  methods: {
    onResize() {
      this.windowWidth = document.body.clientWidth;
      this.windowHeight = document.body.clientHeight;
    },
  },
  watch: {
    windowWidth() {},
    windowHeight() {},
  },
  mounted() {
    this.$nextTick(() => {
      window.addEventListener('resize', this.onResize);
    });
  },
};
</script>

<style lang="sass" scoped>
.section
  .content
    height: calc(100% - 128px)
    width: 1520px
    margin-left: auto
    margin-right: auto
    overflow: hidden
    h2
      margin-top: 84px
    .coverflow
      margin-left: auto
      margin-right: auto
      margin-top: -250px
//##Device = Laptops, Desktops
@media (min-width: 1366px) and (max-width: 1919px)
  .section
    .content
      height: calc(100% - 80px)
      width: 1320px
      h2
        margin-top: 30px
      .coverflow
        margin-top: -150px
//##Device = Tablets, Ipads (portrait)
@media (min-width: 768px) and (max-width: 1365px)
  .section
    background-color: #fff
    .content
      height: auto
      width: 100%
      margin: 0px 40px
      h2
        margin-top: 30px
      .coverflow
        margin-top: -100px
//##Device = Most of the Smartphones Mobiles (Portrait)
@media (min-width: 320px) and (max-width: 767px)
  .section
    background-color: #fff
    .content
      height: auto
      width: 100%
      margin: 0px 15px
      h2
        margin-top: 30px
      .coverflow
        margin-top: -200px
@media (min-width: 320px) and (max-width: 549px)
  .section
    .content
      .coverflow
        margin-left: -125px
@media (min-width: 550px) and (max-width: 767px)
  .section
    .content
      .coverflow
        margin-left: auto
        margin-right: auto
</style>
