<template>
  <button :style="styles" @click="callback" :type="type">
    <span :style="textStyles" class="button-text">
      <slot />
    </span>
  </button>
</template>

<script>
export default {
  props: {
    styles: Object,
    textStyles: Object,
    callback: {
      type: Function,
      default: () => {},
    },
    type: {
      type: String,
      default: 'button',
    },
  },
};
</script>

<style lang="sass" scoped>
button
  background-color: var(--accent-color)
  padding: 4px 64px
  width: fit-content
  .button-text
    font-family: SST, Helvetica, Arial, sans-serif
    font-weight: 500
    font-size: 18px
    line-height: 30px
    color: #ffffff
//##Device = Laptops, Desktops
@media (min-width: 1366px) and (max-width: 1919px)

//##Device = Tablets, Ipads (portrait)
@media (min-width: 768px) and (max-width: 1365px)
  button
    padding: 11px 94px
    .button-text
      font-size: 30px
      line-height: 51px
//##Device = Most of the Smartphones Mobiles (Portrait)
@media (min-width: 320px) and (max-width: 767px)
</style>
