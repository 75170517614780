<template>
  <div class="section">
    <Header class="opaque" :opaque="true"/>
    <div class="content-container">
      <img
        src="@/assets/bg.jpg"
        alt=""
        class="bg"
        draggable="false"
      >
      <div class="content">
        <h2>The Working Process</h2>
        <div class="text-column">
          <div class="text-block">
            <div class="number">1</div>
            <p>We work and provide our customers with reliable services to achieve greatness all
              made possible with the specially designed conditions to allow our employees to
              function more efficiently. Our excellent services allow for the justification of our
              work that is transmitted through our customers. We achieve that goal with by making
              sure our office staff and courteous drivers are happy and motivated. We work as a
              team to consistently provide the best possible service for our customers.</p>
          </div>
          <div class="delimeter"></div>
          <div class="text-block">
            <div class="number">2</div>
            <p>We have eliminated the middleman role to give a better value
              to our customer. We are excited to expand our business with the addition of a new
              distribution center in the upcoming year. This is about you- the customers. We need
              your trust, and the only way to achieve this is by providing excellent service,
              unbeatable customer support, safe tracking, and doing it all at a fair competitive
              price. You can always rely on our proficiency and experience. We work as a team to
              provide excellent service for our customers.</p>
          </div>
          <div class="delimeter"></div>
          <div class="text-block">
            <div class="number">3</div>
            <p>Technology plays a big part in our institution, and we take its full advantages
              to offer adequate services to our customers while also maintaining a level playing
              ground. Our employees have been provided with a working environment that allows them
              to be tech-savvy, time-conscious and considerate without compromise. We have absolute
              trust in our employees to attain the height of being the best in the industry.</p>
          </div>
        </div>
        <img src="@/assets/man.png" alt="" draggable="false">
      </div>
    </div>
  </div>
</template>

<script>
import Header from '@/components/Header.vue';

export default {
  components: {
    Header,
  },
};
</script>

<style lang="sass" scoped>
.section
  height: inherit
  .content-container
    position: relative
    height: calc(100% - 128px)
    width: 100%
    .bg
      position: absolute
      left: 0
      z-index: -100
      height: 100%
      width: 100%
    .content
      height: 100%
      width: 1520px
      margin-left: auto
      margin-right: auto
      display: flex
      h2
        margin-top: 84px
        width: 392px
      .text-column
        display: flex
        flex-direction: column
        margin: 76px 75px 0px 104px
        .delimeter
          height: 3px
          width: 535px
          background-color: #ececec
        .text-block
          position: relative
          margin: 35px 0px
          .number
            font-weight: 500
            font-size: 40px
            line-height: 52px
            color: var(--accent-color)
            position: absolute
            top: -26px
            left: -40px
          p
            font-size: 13px
            line-height: 22px
            color: #262626
            width: 532px
      img
        width: 407px
        height: 610px
        margin-top: 98px
//##Device = Laptops, Desktops
@media (min-width: 1366px) and (max-width: 1919px)
  .section
    .content-container
      height: calc(100% - 80px)
      .bg
      .content
        width: 1320px
        h2
        .text-column
          margin: 80px -20px 0px 80px
          .delimeter
            width: 100%
          .text-block
            margin: 20px 0px
            .number
              font-size: 30px
            p
              width: 670px
        img
          transform: scale(0.625)
          margin-top: 0px
          margin-right: -70px
//##Device = Tablets, Ipads (portrait)
@media (min-width: 768px) and (max-width: 1365px)
  .section
    background-color: #fff
    .content-container
      height: auto
      .bg
        z-index: 0
        height: 100%
        width: auto
      .content
        width: 100%
        padding: 0px 40px
        flex-direction: column
        h2
          z-index: 1
          margin-top: 40px
          width: 100%
        .text-column
          margin: 80px 0px 60px 0px
          .delimeter
            width: 100%
          .text-block
            margin: 15px 0px
            .number
              font-size: 60px
              left: 10px
            p
              width: calc(100% - 120px)
              font-size: 20px
              line-height: 34px
              margin-left: 80px
        img
          display: none
//##Device = Most of the Smartphones Mobiles (Portrait)
@media (min-width: 320px) and (max-width: 767px)
  .section
    background-color: #fff
    .content-container
      height: auto
      .bg
        z-index: 0
        height: 100%
        width: auto
      .content
        width: 100%
        padding: 0px 15px
        flex-direction: column
        h2
          z-index: 1
          margin-top: 40px
          width: 100%
        .text-column
          margin: 40px 0px 40px 0px
          .delimeter
            width: 100%
          .text-block
            margin: 15px 0px
            .number
              font-size: 30px
              left: 5px
            p
              width: calc(100% - 48px)
              font-size: 14px
              line-height: 21px
              margin-left: 32px
        img
          display: none
</style>
