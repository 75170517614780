<template>
  <div id="app">
    <a class="skip-link" href="#app">Skip to main</a>
    <router-view/>
  </div>
</template>

<script>

export default {
  name: 'App',
};
</script>
<style lang="sass">
@import './styles/anim'
@import './styles/shakingButtonAnimation'
@import './styles/fonts'
@import './styles/colors'
*
  box-sizing: border-box
  &:focus
    outline: #7BC24D
  margin: 0
  padding: 0
html, body
  margin: 0px
  scroll-behavior: smooth
  overflow-x: hidden
  max-width: 100%
img
  user-select: none
a
  color: black
  text-decoration: unset
button
  cursor: pointer
  user-select: none
  display: block
  border: 0px
  border-radius: 0px
input
  -webkit-appearance: none
  -moz-appearance: none
  appearance: none
h1, h2, h3, h4, h5, h6, p, ul
  margin: unset
  padding: unset
  font-size: 14px
  text-align: start
  // line-height: 24px
h1
  font-weight: 500
  font-size: 84px
  letter-spacing: 0.025em
h2
  font-weight: 500
  font-size: 40px
  color: #262626
.skip-link
  position: absolute
  top: -100px
  left: 0
  background: #000000
  color: white
  padding: 8px
  z-index: 100
  &:focus
    top: 0
.section
  text-align: center
  color: aliceblue
#app
  font-family: SST, Helvetica, Arial, sans-serif
  font-weight: 400
  font-style: normal
  font-size: 14px
  // line-height: 24px
  -webkit-font-smoothing: antialiased
  -moz-osx-font-smoothing: grayscale
.icon-shaking
  &:hover
    -webkit-animation: shakingButton 500ms linear 1
    animation: shakingButton 500ms linear 1
//##Device = Laptops, Desktops
@media (min-width: 1366px) and (max-width: 1919px)
h1
  font-size: 50px
h2
  font-size: 30px
//##Device = Tablets, Ipads (portrait)
@media (min-width: 768px) and (max-width: 1365px)
h1
  font-size: 60px
h2
  font-size: 32px
//##Device = Most of the Smartphones Mobiles (Portrait)
@media (min-width: 320px) and (max-width: 767px)
h1
  font-size: 42px
h2
  font-size: 32px
@media (max-width: 319px)
  body, html
    display: none
</style>
