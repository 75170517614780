// import router from '../router';

const BASEURL = 'http://backend.allstatecargoinc.com/api';
// eslint-disable-next-line no-useless-escape
const licenseKey = 'wwwdwdd\-ww\\ddww-wd\d\d\d-\ddddd\w\dw\\ddd-\d\wwww\-w\\\w\dw-\dwwwdddw\wdddd\-dwdw\www-wdw\ww\w-wddwwwdw\\\\ddww-wdwd\\w\-\ddw\wdd-wdd\dddwaI/al]"flEdNg{cYFz[hl{] i#,b0@%B]SCv%3Eepfc "9J=QsWt+J"qNSI-2uj:y&[bt18h8<^*m~~[6+h}(H{XLF$buu}`@';
const mapApiKey = 'AIzaSyAznDvuAFxaWdQI7K6RBqBqqPMlr5a-cj4';
// const GOOGLE_TRACKING_ID = 'UA-180645468-1';

// const GOOGLE_ANALYTICS_CONFIG = {
//   appName: 'Epark',
//   appVersion: '1.0.0',
//   trackingId: GOOGLE_TRACKING_ID,
//   vueRouter: router,
// };

// const YANDEX_METRIKA_ID = 68334130;

// const YANDEX_METRIKA_CONFIG = {
//   id: YANDEX_METRIKA_ID,
//   router,
//   env: 'production',
// };

export default {
  BASEURL,
  licenseKey,
  mapApiKey,
  // GOOGLE_ANALYTICS_CONFIG,
  // YANDEX_METRIKA_CONFIG,
};
