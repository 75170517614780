<template>
  <div
    class="dark-bg"
    ref="modalContainer"
    id="modalContainer"
    @keyup.esc="close"
    @click.self="close"
  >
    <div class="modal">
      <CloseIcon class="close" @click="close"/>
      <h3>Application Accepted</h3>
      <p>Our employee will call you back within 5 minutes.</p>
      <Button :callback="close">Close</Button>
    </div>
  </div>
</template>

<script>
import CloseIcon from '@/assets/icons/close.svg';
import Button from '@/components/Button.vue';

export default {
  components: {
    CloseIcon,
    Button,
  },
  methods: {
    close() {
      this.$refs.modalContainer.style.display = 'none';
    },
  },
};
</script>

<style lang="sass" scoped>
.dark-bg
  background-color: rgba(38,38,38,0.7)
  position: absolute
  top: 0px
  left: 0px
  width: 100%
  height: inherit
  display: none
  flex-direction: column
  align-items: center
  justify-content: center
  z-index: 200
  .modal
    background-color: #ffffff
    position: relative
    min-width: 396px
    min-height: 214px
    padding: 45px 45px 35px 45px
    .close
      position: absolute
      top: 9px
      right: 9px
      cursor: pointer
    h3
      font-weight: 500
      font-size: 30px
      line-height: 39px
      color: #2f2f2f
    p
      font-size: 11px
      line-height: 19px
      color: #2f2f2f
      margin: 25px 0px 30px 0px
    button
//##Device = Laptops, Desktops
@media (min-width: 1366px) and (max-width: 1919px)

//##Device = Tablets, Ipads (portrait)
@media (min-width: 768px) and (max-width: 1365px)
  .dark-bg
    position: fixed
    height: 100vh
    .modal
      min-width: unset
      width: calc(100% - 30px)
      max-width: 600px
      padding: 80px 80px 60px 80px
      .close
        top: 45px
        right: 45px
      h3
      p
        font-size: 20px
        line-height: 34px
        margin: 53px 0px 81px 0px
      button
        margin-left: auto
        margin-right: auto
//##Device = Most of the Smartphones Mobiles (Portrait)
@media (min-width: 320px) and (max-width: 767px)
  .dark-bg
    position: fixed
    height: 100vh
    .modal
      min-width: unset
      width: calc(100% - 30px)
      max-width: 396px
</style>
