import Vue from 'vue';
import VueRouter from 'vue-router';
import HomePage from '@/views/HomePage.vue';

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    name: 'Home Page',
    component: HomePage,
  },
  {
    path: '/about-us',
    name: 'About Us Page',
    component: () => import('@/views/AboutUsPage.vue'),
  },
  {
    path: '/services',
    name: 'Services Page',
    component: () => import('@/views/ServicesPage.vue'),
  },
  {
    path: '/careers',
    name: 'Careers Page',
    component: () => import('@/views/CareersPage.vue'),
  },
  {
    path: '/contact-us',
    name: 'Contact Us Page',
    component: () => import('@/views/ContactUsPage.vue'),
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    }
    return { x: 0, y: 0 };
  },
});

export default router;
