<template>
  <div class="section">
    <Header class="opaque" :opaque="true"/>
    <div class="content">
      <h2>About Us</h2>
      <div class="mid">
        <video
          src="@/assets/video/flag.mp4"
          ref="video"
          autoplay
          muted
          loop
          preload
          playsinline
          data-autoplay
          data-keepplaying
        ></video>
        <div class="right">
          <p>We’re dedicated to going the extra mile for our customers by delivering freight
            service nationwide, 24/7/365. Imagine the peace of mind you’ll gain from dealing
            with a company whose reputation is earned by specializing in a service segment where
            the margin of error is virtually zero. For years, our organization has blazed new
            trails for reliability and service as a leader in the management of transportation.</p>
          <p>We operate under the industry’s most stringent guidelines for selecting and managing
            the resources of thousands of carriers, from national fleets to small regional trucking
            companies. At Allstate cargo, we put the world’s best equipment and personnel, our
            superior customer service, and transportation and logistics services right at your
            fingertips. Our network of nine regional offices have managed hundreds of thousands of
            shipments with a singular goal of bringing you the best customer experience.</p>
          <Button :callback="redirect">READ MORE</Button>
        </div>
      </div>
      <div class="bot">
        <div class="icon-1 goods">
          <Icon1 class="icon-shaking"/>
        </div>
        <div class="icon-2 goods">
          <Icon2 class="icon-shaking" />
        </div>
        <div class="icon-3 goods">
          <Icon3 class="icon-shaking" />
        </div>
        <div class="goods-1 goods-text">
          <div class="icon-in-item">
            <Icon1 class="icon-shaking" />
          </div>
          <h6>Warehousing & Cross-dock</h6>
          <p>Short and long-term storage solutions. Local drivers to distribute
            anywhere in the Chicago area. OTR drivers to deliver anywhere in theUSA or Canada.</p>
        </div>
        <div class="goods-2 goods-text">
          <div class="icon-in-item">
            <Icon2 class="icon-shaking" />
          </div>
          <h6>24/7 service</h6>
          <p>Please contact us with your questions and concerns by calling the
            customer service number. Our customer care service is online 24/7</p>
        </div>
        <div class="goods-3 goods-text">
          <div class="icon-in-item">
            <Icon3 class="icon-shaking" />
          </div>
          <h6>70+ POWER UNITS</h6>
          <p>Our power plants exploit many blocks with a capacity of approximately
            70+PU. There is nothing related to power failure leading to damage of loads. We have
            got you covered.</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Header from '@/components/Header.vue';
import Button from '@/components/Button.vue';
import Icon1 from '@/assets/icons/goods-1.svg';
import Icon2 from '@/assets/icons/goods-2.svg';
import Icon3 from '@/assets/icons/goods-3.svg';

export default {
  components: {
    Header,
    Button,
    Icon1,
    Icon2,
    Icon3,
  },
  methods: {
    redirect() {
      this.$router.push({ name: 'About Us Page' });
    },
  },
};
</script>

<style lang="sass" scoped>
.section
  .content
    height: calc(100% - 128px)
    width: 1520px
    margin-left: auto
    margin-right: auto
    h2
      margin-top: 84px
    .mid
      display: flex
      margin-top: 46px
      video
      .right
        display: flex
        flex-direction: column
        margin-left: 111px
        p
          color: #262626
          margin-bottom: 35px
          line-height: 24px
          &:last-child
            margin-bottom: 0px
        button
          margin-top: 42px
    .bot
      margin-top: 110px
      border: solid 1px #ececec
      width: 1147px
      height: 183px
      margin-left: auto
      margin-right: auto
      position: relative
      display: flex
      justify-content: space-evenly
      align-items: flex-start
      .goods
        position: absolute
        top: -46px
        padding: 11px
        background-color: #ffffff
      .icon-1
        left: 183px
      .icon-2
        left: 530px
      .icon-3
        left: 870px
      .goods-text
        display: flex
        flex-direction: column
        align-items: center
        max-width: 352px
        height: 100%
        margin-top: 50px
        .icon-in-item
          display: none
        h6
          font-weight: 500
          font-size: 18px
          line-height: 31px
          color: #262626
        p
          font-size: 11px
          line-height: 19px
          color: #acacac
//##Device = Laptops, Desktops
@media (min-width: 1366px) and (max-width: 1919px)
  .section
    .content
      height: calc(100% - 80px)
      width: 1320px
      h2
        margin-top: 30px
      .mid
        margin-top: 0px
        video
          transform: scale(0.625)
        .right
          margin-top: 20px
          margin-left: 0px
          p
          button
            margin-top: 0px
      .bot
        margin-top: 80px
//##Device = Tablets, Ipads (portrait)
@media (min-width: 768px) and (max-width: 1365px)
  .section
    background-color: #fff
    .content
      height: auto
      width: 100%
      margin: 0px
      padding: 50px 40px 0px 40px
      h2
        margin-top: 0px
      .mid
        width: 100%
        flex-direction: column
        margin-top: 92px
        video
          width: 100%
          max-width: 700px
          margin: 4px auto 17px auto
        .right
          margin-top: 20px
          margin-left: 0px
          p
            margin: 93px auto 83px auto
            width: 100%
            font-size: 20px
            line-height: 37px
            max-width: 700px
            &:nth-child(2)
              display: none
          button
            margin: 0px auto 0px auto
      .bot
        flex-direction: column
        margin-top: 128px
        width: 100%
        height: auto
        border: none
        .goods
          display: none
        .goods-text
          max-width: 700px
          margin: 45px auto
          border-bottom: solid 1px #ececec
          &:last-child
            border-bottom: none
          padding-bottom: 90px
          .icon-in-item
            width: 165px
            height: 163px
            display: block
            svg
              transform: scale(2.3)
              margin-top: 46px
          h6
            font-size: 26px
            line-height: 44px
            margin: 60px 0px
          p
            font-size: 20px
            line-height: 34px
            text-align: center
//##Device = Most of the Smartphones Mobiles (Portrait)
@media (min-width: 320px) and (max-width: 767px)
  .section
    background-color: #fff
    .content
      height: auto
      width: 100%
      margin: 0px
      padding: 50px 15px 0px 15px
      h2
        margin-top: 0px
      .mid
        width: 100%
        flex-direction: column
        margin-top: 0px
        video
          width: 100%
          max-width: 500px
          margin: 4px auto 17px auto
        .right
          margin-top: 20px
          margin-left: 0px
          p
            margin: 0px auto 40px auto
            width: 100%
            max-width: 500px
            &:nth-child(2)
              display: none
          button
            width: 100%
            max-width: 500px
            margin: 0px auto 0px auto
      .bot
        flex-direction: column
        margin-top: 80px
        width: 100%
        height: auto
        border: none
        .goods
          display: none
        .goods-text
          max-width: 500px
          margin: 15px auto
          border-bottom: solid 1px #ececec
          padding-bottom: 30px
          &:last-child
            border-bottom: none
          .icon-in-item
            display: block
          h6
            margin: 15px 0px
          p
</style>
