<template>
  <div class="section">
    <div class="bg-video">
      <video
        src="@/assets/video/home.mp4"
        ref="video"
        autoplay
        muted
        loop
        preload
        playsinline
        data-autoplay
        data-keepplaying
      ></video>
    </div>
    <div class="main">
      <Header />
      <MobileHeader />
      <div class="content">
        <h1>Delivery of goods for centuries</h1>
        <div class="subheading">
          <p>Allstate cargo offers a complete range of logistics services via all modes of
            transport.</p>
          <br>
          <p>Our professional expertise covers every spectrum: From warehousing to follow-up
            shipment services using trailers, trucks and refrigerated vans.</p>
          <br>
          <p>We are unique in customizing services to satisfy your special requirements.
            Allstate cargo has built a solid reputation in providing our customers with
            reliable transportation management and on-time delivery of your shipments,
            worldwide.</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Header from '@/components/Header.vue';
import MobileHeader from '@/components/MobileHeader.vue';

export default {
  components: {
    Header,
    MobileHeader,
  },
};
</script>

<style lang="sass" scoped>
@import "@/styles/_mixins.sass"
@include block0()
</style>
