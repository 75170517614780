<template>
  <div class="page">
    <full-page
      ref="fullpage"
      :options="options"
      id="fullpage"
      v-if="device === 'desktop'"
    >
      <Block0 />
      <Block1 />
      <Block3 />
      <Block2 />
      <Block4 />
    </full-page>
    <div v-else>
      <Block0 />
      <Block1 />
      <Block3 />
      <Block2 />
      <Block4 />
    </div>
    <!-- <Modal id="modalContainerOfBot" /> -->
    <VueBotUI
      :messages="messages"
      :options="botOptions"
      :input-disable="disableInput"
      @msg-send="messageSendHandler"
    />
  </div>
</template>

<script>
import Block0 from '@/components/home/block0.vue';
import Block1 from '@/components/home/block1.vue';
import Block2 from '@/components/home/block2.vue';
import Block3 from '@/components/home/block3.vue';
import Block4 from '@/components/home/block4.vue';
// import Modal from '@/components/Modal.vue';
import { VueBotUI } from 'vue-bot-ui';
import axios from 'axios';
import Consts from '@/util/consts';
import BotAvatar from '@/assets/logoshort.png';

export default {
  components: {
    Block0,
    Block1,
    Block2,
    Block3,
    Block4,
    // Modal,
    VueBotUI,
  },
  data() {
    return {
      options: {
        menu: '#menu',
        anchors: ['block0', 'block1', 'block3', 'block2', 'block4'],
        licenseKey: Consts.licenseKey,
      },
      messages: [
        {
          agent: 'bot',
          type: 'button',
          text: 'Thank you for your interest. Press button below. You are applying for:',
          options: [
            {
              text: 'Solo driver',
              value: '0',
              action: 'postback',
            },
            {
              text: 'Team driver',
              value: '1',
              action: 'postback',
            },
          ],
        },
      ],
      botOptions: {
        botTitle: 'Allstate Cargo Bot',
        colorScheme: 'var(--accent-color)',
        msgBubbleBgBot: 'var(--accent-color)',
        msgBubbleColorBot: '#fff',
        msgBubbleBgUser: '#f0f0f0',
        msgBubbleColorUser: '#000',
        botAvatarImg: BotAvatar,
      },
      disableInput: true,
      previousMessageValue: -1,
      experience: '',
      fullName: '',
      email: '',
      phone: '',
      windowWidth: document.body.clientWidth,
      windowHeight: document.body.clientHeight,
      device: 'desktop',
    };
  },
  methods: {
    messageSendHandler(userMessage) {
      // console.log(userMessage);
      this.messages.push(userMessage);
      if (userMessage.value === '0') {
        const botMessage = {
          agent: 'bot',
          type: 'text',
          text: 'We are just hiring team at this moment. Please let us know if you willing to team up with someone else.',
        };
        this.messages.push(botMessage);
        this.disableInput = true;
        return;
      }
      if (userMessage.value === '1') {
        const botMessage = {
          agent: 'bot',
          type: 'button',
          text: 'For Team Driver we offer: 70 CPM or 30% from gross. Payment raise in 3 months - 5 cents. Weekly direct deposit. Paid extra stops - 35% & layovers - 150$. New 2019-2020 trucks & trailers. Freightliner Cascadia, Peterbilt & International. Between 6000 up to 75000 miles per week on average. We pay all miles, empty and loaded. Hometime - 2-3 days every 2 weeks. Safety 1st level clean inspection bonus - 300$. 24/7 dispatch.',
          options: [
            {
              text: 'I\'m interested',
              value: '2',
              action: 'postback',
            },
          ],
        };
        this.messages.push(botMessage);
        this.disableInput = true;
        return;
      }
      if (userMessage.value === '2') {
        const botMessage = {
          agent: 'bot',
          type: 'button',
          text: 'Great! To shorten the application process please answer a few questions. How much CDL-A driving experience do you have? Please select below your experience:',
          options: [
            {
              text: '0-5 months',
              value: '3',
              action: 'postback',
            },
            {
              text: '6-12 months',
              value: '4',
              action: 'postback',
            },
            {
              text: '1-2 years',
              value: '4',
              action: 'postback',
            },
            {
              text: '2-5 years',
              value: '4',
              action: 'postback',
            },
            {
              text: '5+ years',
              value: '4',
              action: 'postback',
            },
          ],
        };
        this.messages.push(botMessage);
        this.disableInput = true;
        return;
      }
      if (userMessage.value === '3') {
        const botMessage = {
          agent: 'bot',
          type: 'text',
          text: 'We require 6 months verifiable CDL-A experience at least. Thank you for your time.',
        };
        this.messages.push(botMessage);
        this.disableInput = true;
        return;
      }
      if (userMessage.value === '4') {
        this.experience = userMessage.text;
        const botMessage = {
          agent: 'bot',
          type: 'text',
          text: 'Please enter your name?',
        };
        this.messages.push(botMessage);
        this.disableInput = false;
        this.previousMessageValue = 4;
        return;
      }
      if (userMessage.value === undefined && this.previousMessageValue === 4) {
        this.fullName = userMessage.text;
        const botMessage = {
          agent: 'bot',
          type: 'text',
          text: 'Please enter your email address?',
        };
        this.messages.push(botMessage);
        this.disableInput = false;
        this.previousMessageValue = 5;
        return;
      }
      if (userMessage.value === undefined && this.previousMessageValue === 5) {
        this.email = userMessage.text;
        const botMessage = {
          agent: 'bot',
          type: 'text',
          text: 'Please enter your cell phone number so we can reach you for details.',
        };
        this.messages.push(botMessage);
        this.disableInput = false;
        this.previousMessageValue = 6;
        return;
      }
      if (userMessage.value === undefined && this.previousMessageValue === 6) {
        this.phone = userMessage.text;
        const botMessage = {
          agent: 'bot',
          type: 'button',
          text: 'Thank you for your information provided. Check out our vacancies.',
          options: [
            {
              text: 'Check out vacancies',
              value: 'careers',
              action: 'url',
            },
          ],
        };
        this.messages.push(botMessage);
        this.disableInput = true;
        this.previousMessageValue = 7;
        this.proceedCredentials();
      }
    },
    async proceedCredentials() {
      const {
        experience, fullName, email, phone,
      } = this;
      if (experience && fullName && email && phone) {
        const formData = new FormData();
        formData.append('experience', experience);
        formData.append('name', fullName);
        formData.append('email', email);
        formData.append('number', phone);
        try {
          await axios.post('/contacts', formData);
        } catch (e) {
          console.log('Error occured during form submit');
          console.error(e);
        }
      }
    },
    // showModal() {
    //   document.getElementById('modalContainerOfBot').style.display = 'flex';
    // },
    onResize() {
      this.windowWidth = document.body.clientWidth;
      this.windowHeight = document.body.clientHeight;
      if (this.windowWidth >= 1366) {
        this.device = 'desktop';
      } else {
        this.device = 'mobile';
      }
    },
  },
  watch: {
    windowWidth() {},
    windowHeight() {},
  },
  mounted() {
    this.$nextTick(() => {
      window.addEventListener('resize', this.onResize);
    });
    this.onResize();
  },
};
</script>

<style lang="sass">
.page
  position: relative
.qkb-board-header__title
  font-weight: 500!important
  font-size: 18px!important
.qkb-mb-button-options__btn
  border-color: var(--accent-color)!important
  color: var(--accent-color)!important
//##Device = Most of the Smartphones Mobiles (Portrait)
@media (min-width: 320px) and (max-width: 767px)
  .qkb-board
    width: 270px!important
</style>
