<template>
  <header>
    <div class="header-container">
      <router-link to="/" class="logo-link" draggable="false">
        <img
          class="logo"
          :src="require(`@/assets/${logoName}.png`)"
          alt="Allstate Cargo logo"
          draggable="false"
        >
      </router-link>
      <nav>
        <router-link to="/" draggable="false">HOME</router-link>
        <router-link to="/about-us" draggable="false">ABOUT US</router-link>
        <router-link to="/services" draggable="false">SERVICES</router-link>
        <router-link to="/careers" draggable="false">CAREERS</router-link>
        <router-link to="/contact-us" draggable="false">CONTACT US</router-link>
      </nav>
    </div>
  </header>
</template>

<script>

export default {
  props: {
    opaque: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    logoName() {
      return this.opaque ? 'logo1' : 'logo';
    },
  },
};
</script>

<style lang="sass" scoped>
header
  height: 128px
  background-color: transparent
  width: 100%
  .header-container
    width: 1520px
    height: inherit
    margin: 0px auto
    display: flex
    justify-content: space-between
    align-items: center
    nav
      a
        color: #ffffff
        font-size: 17px
        margin-right: 65px
        width: fit-content
        transition: all 0.2s ease-in-out
        position: relative
        &:last-child
          margin-right: 0px
        &:hover
          &:before, &:after
            width: 50%
            opacity: 1
        &:before, &:after
          content: "",
          position: absolute
          bottom: -10px
          width: 0px
          height: 2px
          margin: 5px 0px 0px
          transition: all 0.2s ease-in-out
          transition-duration: 0.35s
          opacity: 0
          background-color: #ffffff
        &:before
          left: 50%
        &:after
          right: 50%
.opaque
  background-color: #EDEDED
  .header-container
    nav
      a
        color: #262626
        &:before, &:after
          background-color: var(--accent-color)
//##Device = Laptops, Desktops
@media (min-width: 1366px) and (max-width: 1919px)
  header
    height: 80px
    .header-container
      width: 1320px
      .logo-link
        width: 150px
        height: 51px
        .logo
          width: inherit
          height: inherit
      nav
        a
          font-size: 16px
          margin-right: 40px
//##Device = Tablets, Ipads (portrait)
@media (min-width: 768px) and (max-width: 1365px)
  header
    display: none
//##Device = Most of the Smartphones Mobiles (Portrait)
@media (min-width: 320px) and (max-width: 767px)
  header
    display: none
</style>
